import React, {useEffect} from 'react';
import './App.css';


function App() {
  useEffect(()=>{
    window.location.href = "https://www.linkedin.com/in/aimaniva/";
  },[]);
  return (
    <div>

    </div>
  );
}

export default App;
